<template>
  <div @click.stop="close">
    <div>
      <button
        type="button"
        class="btn btn-primary"
        id="button-open-dialog"
        data-bs-toggle="modal"
        data-bs-target="#kt_modal_1"
        hidden
      ></button>
      <div
        class="modal fade"
        tabindex="-1"
        id="kt_modal_1"
        data-bs-backdrop="static"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="d-flex justify-content-between">
              <h2 class="modal-title mt-5 ms-7">เลือกรายการสินค้า</h2>
              <button
                data-bs-dismiss="modal"
                @click="close"
                type="button"
                class="btn btn-sm"
              >
                <i
                  class="bi bi-x m-0 p-0"
                  id="close-btn"
                  style="font-size: 25px"
                ></i>
              </button>
            </div>

            <div class="modal-body pt-0 pb-0">
              <hr class="mb-0" />
              <div class="row">
                <div
                  v-for="(item, index) in dataItemsShow"
                  :key="index"
                  class="col-12 col-md-6 col-lg-6 col-xl-6 mt-6"
                >
                  <div
                    class="card shadow-sm card-prod-item"
                    style="cursor: pointer"
                    @click="onClickCard(index)"
                  >
                    <div class="row">
                      <div class="col-12 display-flex justify-content-end">
                        <input
                          :id="`check-prod-${index}`"
                          class="form-check-input mt-1 ms-1"
                          style="position: absolute"
                          type="checkbox"
                          :key="index"
                          :checked="
                            this.selectedItem.length < 1
                              ? false
                              : this.selectedItem[0].index === index
                              ? true
                              : false
                          "
                          @click="onSelectProduct(item, $event, index)"
                        />
                      </div>
                    </div>
                    <div
                      style="
                        position: absolute;
                        bottom: 102px;
                        color: white;
                        font-size: 20px;
                        font-weight: 900;
                      "
                      class="ms-2 shadow-sm"
                    >
                      {{
                        item.product_name.length > 15
                          ? item.product_name.substring(0, 15) + "..."
                          : item.product_name
                      }}
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <img
                          style="
                            max-height: 135px;
                            width: 100%;
                            background-size: cover;
                            background-position: center center;
                            border-top-right-radius: 8px;
                            border-top-left-radius: 8px;
                          "
                          :src="item.img"
                        />
                      </div>
                    </div>

                    <div class="row ps-5 pe-5 pb-0">
                      <div class="col-5 text-end pt-2">
                        <div class="fw-boldest">รหัสสินค้า</div>
                        <div class="fw-boldest">ชื่อสินค้า</div>
                        <div class="fw-boldest">เหลือสต๊อค</div>
                        <div class="fw-boldest">ราคาขาย</div>
                      </div>
                      <div class="col-7 pt-2 pb-2">
                        <div>{{ item.product_code }}</div>
                        <div>
                          {{
                            item.product_name.length > 10
                              ? item.product_name.substring(0, 20) + "..."
                              : item.product_name
                          }}
                        </div>
                        <div>{{ formatPrice(item.product_bring_qty) }}</div>
                        <div>
                          {{
                            formatPrice(
                              item.product_promotion_price
                                ? item.product_promotion_price
                                : item.product_retail_price
                            )
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-7">
                <Pagination
                  :items="tableItems"
                  @filterItems="filterItems"
                  @handleChangePage="handleChangePage"
                />
              </div>

              <hr class="mt-7" />
            </div>

            <footer class="pb-5">
              <div class="row">
                <div class="col-6 ms-7">
                  <button
                    @click="submit"
                    type="button"
                    class="btn btn-primary me-3"
                    data-bs-dismiss="modal"
                    :disabled="loadingUpdate"
                  >
                    <div
                      v-if="loadingUpdate"
                      class="spinner-grow spinner-grow-sm text-light"
                      role="status"
                    ></div>
                    ตกลง
                  </button>
                  <button
                    @click="close"
                    type="button"
                    class="btn btn-light"
                    data-bs-dismiss="modal"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            </footer>
            <!-- <div class="modal-footer d-flex justify-content-start">
            <hr class="pt-0 mt-0" style="color: LightGrey" />
          </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "./pagination.vue";

import numbro from "numbro";

export default {
  components: { Pagination },
  props: {
    dialogConfirmByPass: Boolean,
    loadingUpdate: Boolean,
    productItemData: Array,
  },
  data: () => ({
    dataItemsShow: [],
    tableItems: [],
    allData: [],

    selectedItem: [],
  }),
  watch: {
    dialogConfirmByPass(val) {
      if (val) {
        this.selectedItem = [];
        this.setData();
        document.getElementById("button-open-dialog").click();
      }
    },
  },
  methods: {
    setData() {
      this.dataItemsShow = this.productItemData;
      this.tableItems = this.productItemData;
      this.allData = this.productItemData;
    },
    submit() {
      this.$emit("submitConfirmPass", this.selectedItem);
      this.close();
    },
    close() {
      this.$emit("closeDialogConformByPass");
    },

    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },

    onClickCard(index) {
      document.getElementById("check-prod-" + index).click();
    },

    onSelectProduct(item, e, index) {
      this.selectedItem.splice(0, 1, { ...item, index: index });
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .display-phone {
    text-align: left !important;
  }
}
#close-btn:hover {
  background-color: LightGrey;
  border-radius: 25px;
  color: white;
}
</style>
