import { createRouter, createWebHashHistory } from "vue-router";

import type { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  //login system admin
  {
    path: "/login-sysadmin",
    name: "loginSysAdmin",
    component: () => import("@/views/LoginSysAdmin.vue"),
  },
  {
    path: "/sysadmin",
    name: "sysAdmin",
    component: () => import("@/views/PublicSysAdmin.vue"),
  },
  //login
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/public",
    name: "public",
    component: () => import("@/views/Public.vue"),
  },
  //register
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/register/index.vue"),
  },
  {
    path: "/select-company",
    name: "select-company",
    component: () => import("@/views/SelectCompany.vue"),
  },
  {
    path: "/select-branch",
    name: "select-branch",
    component: () => import("@/views/SelectBranch.vue"),
  },

  {
    path: "/forget-password",
    name: "forget-password",
    component: () => import("@/views/ForgetPassword.vue"),
  },

  // email-template
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("@/views/email-template/resetPassword.vue"),
  },
  {
    path: "/reset-approval-password",
    name: "reset-approval-password",
    component: () => import("@/views/email-template/resetApprovalPassword.vue"),
  },
  {
    path: "/verify-account",
    name: "verify-account",
    component: () => import("@/views/email-template/verifyAccount.vue"),
  },
  {
    path: "/sent-email-success",
    name: "sent-email-success",
    component: () => import("@/views/email-template/sentEmailResetPass.vue"),
  },

  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      // //login
      // {
      //   path: "/login",
      //   name: "login",
      //   component: () => import("@/views/Login.vue"),
      // },

      //dashboard
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },

      //manage-system
      {
        path: "/manage-system/op1",
        name: "OP1",
        component: () => import("@/views/manage-system/OP1/index.vue"),
      },
      {
        path: "/manage-system/op1/new",
        name: "OP1-new",
        component: () => import("@/views/manage-system/OP1/new.vue"),
      },
      {
        path: "/manage-system/op1/edit",
        name: "OP1-edit",
        component: () => import("@/views/manage-system/OP1/edit.vue"),
      },
      //---
      {
        path: "/manage-system/op2",
        name: "OP2",
        component: () => import("@/views/manage-system/OP2/index.vue"),
      },
      {
        path: "/manage-system/op2/list",
        name: "OP2-list",
        component: () => import("@/views/manage-system/OP2/list.vue"),
      },
      {
        path: "/manage-system/op2/new",
        name: "OP2-new",
        component: () => import("@/views/manage-system/OP2/new.vue"),
      },
      {
        path: "/manage-system/op2/edit",
        name: "OP2-edit",
        component: () => import("@/views/manage-system/OP2/edit.vue"),
      },
      //---
      {
        path: "/manage-system/op3",
        name: "OP3",
        component: () => import("@/views/manage-system/OP3/index.vue"),
      },

      //warehouse
      {
        path: "warehouse/WH1",
        name: "WH1",
        component: () => import("@/views/warehouse/WH1/index.vue"),
      },
      {
        path: "warehouse/WH1/new",
        name: "WH1-new",
        component: () => import("@/views/warehouse/WH1/new.vue"),
      },
      {
        path: "warehouse/WH1/edit",
        name: "WH1-edit",
        component: () => import("@/views/warehouse/WH1/edit.vue"),
      },
      //---
      {
        path: "warehouse/WH2",
        name: "WH2",
        component: () => import("@/views/warehouse/WH2/index.vue"),
      },
      {
        path: "warehouse/WH2/new",
        name: "WH2-new",
        component: () => import("@/views/warehouse/WH2/new.vue"),
      },
      {
        path: "warehouse/WH2/edit",
        name: "WH2-edit",
        component: () => import("@/views/warehouse/WH2/edit.vue"),
      },
      //---
      {
        path: "warehouse/WH3",
        name: "WH3",
        component: () => import("@/views/warehouse/WH3/index.vue"),
      },
      {
        path: "warehouse/WH3/new",
        name: "WH3-new",
        component: () => import("@/views/warehouse/WH3/new.vue"),
      },
      {
        path: "warehouse/WH3/edit",
        name: "WH3-edit",
        component: () => import("@/views/warehouse/WH3/edit.vue"),
      },
      //---
      {
        path: "warehouse/WH4",
        name: "WH4",
        component: () => import("@/views/warehouse/WH4/index.vue"),
      },
      {
        path: "warehouse/WH4/new",
        name: "WH4-new",
        component: () => import("@/views/warehouse/WH4/new.vue"),
      },
      {
        path: "warehouse/WH4/edit",
        name: "WH4-edit",
        component: () => import("@/views/warehouse/WH4/edit.vue"),
      },
      //---
      {
        path: "warehouse/WH5",
        name: "WH5",
        component: () => import("@/views/warehouse/WH5/index.vue"),
      },
      {
        path: "warehouse/WH5/new",
        name: "WH5-new",
        component: () => import("@/views/warehouse/WH5/new.vue"),
      },
      {
        path: "warehouse/WH5/edit",
        name: "WH5-edit",
        component: () => import("@/views/warehouse/WH5/edit.vue"),
      },
      //---
      {
        path: "warehouse/WH6-1",
        name: "WH6-1",
        component: () => import("@/views/warehouse/WH6-1/index.vue"),
      },
      {
        path: "warehouse/WH6-1/new",
        name: "WH6-1-new",
        component: () => import("@/views/warehouse/WH6-1/new.vue"),
      },
      {
        path: "warehouse/WH6-1/edit",
        name: "WH6-1-edit",
        component: () => import("@/views/warehouse/WH6-1/edit.vue"),
      },
      //---
      {
        path: "warehouse/WH6-2",
        name: "WH6-2",
        component: () => import("@/views/warehouse/WH6-2/index.vue"),
      },
      // {
      //   path: "warehouse/WH6-2/new",
      //   name: "WH6-2-new",
      //   component: () => import("@/views/warehouse/WH6-2/new.vue"),
      // },
      // {
      //   path: "warehouse/WH6-2/edit",
      //   name: "WH6-2-edit",
      //   component: () => import("@/views/warehouse/WH6-2/edit.vue"),
      // },
      //---
      {
        path: "warehouse/WH6-3",
        name: "WH6-3",
        component: () => import("@/views/warehouse/WH6-3/index.vue"),
      },
      {
        path: "warehouse/WH6-3/new",
        name: "WH6-3-new",
        component: () => import("@/views/warehouse/WH6-3/new.vue"),
      },
      {
        path: "warehouse/WH6-3/edit",
        name: "WH6-3-edit",
        component: () => import("@/views/warehouse/WH6-3/edit.vue"),
      },
      //---
      {
        path: "warehouse/WH6-4",
        name: "WH6-4",
        component: () => import("@/views/warehouse/WH6-4/index.vue"),
      },
      //---
      {
        path: "warehouse/WH7",
        name: "WH7",
        component: () => import("@/views/warehouse/WH7/index.vue"),
      },
      {
        path: "warehouse/WH7/list",
        name: "WH7-list",
        component: () => import("@/views/warehouse/WH7/list.vue"),
      },

      //purchase
      {
        path: "/purchase/PU1",
        name: "purchase-pu1",
        component: () => import("@/views/purchase/PU1/index.vue"),
      },
      {
        path: "/purchase/PU1/new",
        name: "purchase-pu1-new",
        component: () => import("@/views/purchase/PU1/new.vue"),
      },
      {
        path: "/purchase/PU1/edit",
        name: "purchase-pu1-edit",
        component: () => import("@/views/purchase/PU1/edit.vue"),
      },
      //--
      {
        path: "/purchase/PU2-1",
        name: "purchase-pu2-1",
        component: () => import("@/views/purchase/PU2-1/index.vue"),
      },
      {
        path: "/purchase/PU2-1/new",
        name: "purchase-pu2-1-new",
        component: () => import("@/views/purchase/PU2-1/new.vue"),
      },
      {
        path: "/purchase/PU2-1/edit",
        name: "purchase-pu2-1-edit",
        component: () => import("@/views/purchase/PU2-1/edit.vue"),
      },
      //--
      {
        path: "/purchase/PU2-2",
        name: "purchase-pu2-2",
        component: () => import("@/views/purchase/PU2-2/index.vue"),
      },
      //--
      {
        path: "/purchase/PU2-3",
        name: "purchase-pu2-3",
        component: () => import("@/views/purchase/PU2-3/index.vue"),
      },
      {
        path: "/purchase/PU2-3/productIntoWarehouse",
        name: "purchase-pu2-3-receive",
        component: () =>
          import("@/views/purchase/PU2-3/productIntoWarehouse.vue"),
      },
      //--
      {
        path: "/purchase/PU2-4",
        name: "purchase-pu2-4",
        component: () => import("@/views/purchase/PU2-4/index.vue"),
      },

      //sale
      {
        path: "/sale/SA1",
        name: "sale-SA1",
        component: () => import("@/views/sale/SA1/index.vue"),
      },
      {
        path: "/sale/SA1/new",
        name: "sale-SA1-new",
        component: () => import("@/views/sale/SA1/new.vue"),
      },
      {
        path: "/sale/SA1/edit",
        name: "sale-SA1-edit",
        component: () => import("@/views/sale/SA1/edit.vue"),
      },
      //--
      {
        path: "/sale/SA2-1",
        name: "sale-SA2-1",
        component: () => import("@/views/sale/SA2-1/index.vue"),
      },
      {
        path: "/sale/SA2-1/new",
        name: "sale-SA2-1-new",
        component: () => import("@/views/sale/SA2-1/new.vue"),
      },
      {
        path: "/sale/SA2-1/edit",
        name: "sale-SA2-1-edit",
        component: () => import("@/views/sale/SA2-1/edit.vue"),
      },
      //--
      {
        path: "/sale/SA2-2",
        name: "sale-SA2-2",
        component: () => import("@/views/sale/SA2-2/index.vue"),
      },
      //--
      {
        path: "/sale/SA3-1",
        name: "sale-SA3-1",
        component: () => import("@/views/sale/SA3-1/index.vue"),
      },
      {
        path: "/sale/SA3-1/new",
        name: "sale-SA3-1-new",
        component: () => import("@/views/sale/SA3-1/new.vue"),
      },
      {
        path: "/sale/SA3-1/edit",
        name: "sale-SA3-1-edit",
        component: () => import("@/views/sale/SA3-1/edit.vue"),
      },
      //--
      {
        path: "/sale/SA3-2",
        name: "sale-SA3-2",
        component: () => import("@/views/sale/SA3-2/index.vue"),
      },
      //--
      {
        path: "/sale/SA4-1",
        name: "sale-SA4-1",
        component: () => import("@/views/sale/SA4-1/index.vue"),
      },
      {
        path: "/sale/SA4-1/new",
        name: "sale-SA4-1-new",
        component: () => import("@/views/sale/SA4-1/new.vue"),
      },
      //--
      {
        path: "/sale/SA4-2",
        name: "sale-SA4-2",
        component: () => import("@/views/sale/SA4-2/index.vue"),
      },
      //--
      {
        path: "/sale/SA5",
        name: "sale-SA5",
        component: () => import("@/views/sale/SA5/index.vue"),
      },
      //--
      {
        path: "/sale/daily-retail-summary",
        name: "sale-daily-retail-summary",
        component: () => import("@/views/sale/dailyRetailSummary.vue"),
      },
      //---
      {
        path: "/sale/price-tag",
        name: "sale-price-tag",
        component: () => import("@/views/sale/priceTag.vue"),
      },
      //transfer-product
      {
        path: "/transfer-product/TP1",
        name: "transfer-product-TP1",
        component: () => import("@/views/transfer-product/TP1/index.vue"),
      },
      {
        path: "/transfer-product/TP1/new",
        name: "transfer-product-TP1-new",
        component: () => import("@/views/transfer-product/TP1/new.vue"),
      },
      {
        path: "/transfer-product/TP1/edit",
        name: "transfer-product-TP1-edit",
        component: () => import("@/views/transfer-product/TP1/edit.vue"),
      },
      //---
      {
        path: "/transfer-product/TP2-1",
        name: "transfer-product-TP2-1",
        component: () => import("@/views/transfer-product/TP2-1/index.vue"),
      },
      {
        path: "/transfer-product/TP2-1/new",
        name: "transfer-product-TP2-1-new",
        component: () => import("@/views/transfer-product/TP2-1/new.vue"),
      },
      {
        path: "/transfer-product/TP2-1/edit",
        name: "transfer-product-TP2-1-edit",
        component: () => import("@/views/transfer-product/TP2-1/edit.vue"),
      },
      //---
      {
        path: "/transfer-product/TP2-2",
        name: "transfer-product-TP2-2",
        component: () => import("@/views/transfer-product/TP2-2/index.vue"),
      },
      //---
      {
        path: "/transfer-product/TP3-1",
        name: "transfer-product-TP3-1",
        component: () => import("@/views/transfer-product/TP3-1/index.vue"),
      },
      {
        path: "/transfer-product/TP3-1/new",
        name: "transfer-product-TP3-1-new",
        component: () => import("@/views/transfer-product/TP3-1/new.vue"),
      },
      {
        path: "/transfer-product/TP3-1/edit",
        name: "transfer-product-TP3-1-edit",
        component: () => import("@/views/transfer-product/TP3-1/edit.vue"),
      },
      //---
      {
        path: "/transfer-product/TP3-2",
        name: "transfer-product-TP3-2",
        component: () => import("@/views/transfer-product/TP3-2/index.vue"),
      },

      // report
      {
        path: "/report/summary-sales-customer-report",
        name: "summary-sales-customer-report",
        component: () => import("@/views/report/RP-1/index.vue"),
      },
      {
        path: "/report/summary-weekly-report",
        name: "summary-weekly-report",
        component: () => import("@/views/report/RP-2/index.vue"),
      },
      {
        path: "/report/summary-best-seller-report",
        name: "summary-best-seller-report",
        component: () => import("@/views/report/RP-3/index.vue"),
      },
      {
        path: "/report/summary-transaction-report",
        name: "summary-transaction-report",
        component: () => import("@/views/report/RP-4/index.vue"),
      },
      {
        path: "/report/summary-warehouse-report",
        name: "summary-warehouse-report",
        component: () => import("@/views/report/RP-5/index.vue"),
      },
      {
        path: "/report/product-movement",
        name: "product-movement",
        component: () => import("@/views/report/RP-6/index.vue"),
      },

      // setting
      {
        path: "/setting/shop-info",
        name: "shop-info",
        component: () => import("@/views/setting/shop-info/index.vue"),
      },
      {
        path: "/setting/shop-info-detail",
        name: "shop-info-detail",
        component: () => import("@/views/setting/shop-info/detail.vue"),
      },

      {
        path: "/setting/company-branch",
        name: "company-branch",
        component: () => import("@/views/setting/company-branch/index.vue"),
      },
      {
        path: "/setting/company-branch-new",
        name: "company-branch-new",
        component: () => import("@/views/setting/company-branch/new.vue"),
      },
      {
        path: "/setting/company-branch-edit",
        name: "company-branch-edit",
        component: () => import("@/views/setting/company-branch/edit.vue"),
      },

      {
        path: "/setting/user-profile",
        name: "user-profile",
        component: () => import("@/views/setting/user-profile/index.vue"),
      },
      {
        path: "/setting/user-profile-detail",
        name: "user-profile-detail",
        component: () => import("@/views/setting/user-profile/detail.vue"),
      },
      {
        path: "/setting/user-profile-new",
        name: "user-profile-new",
        component: () => import("@/views/setting/user-profile/new.vue"),
      },

      {
        path: "/setting/change-password",
        name: "change-password",
        component: () => import("@/views/setting/change-pass/index.vue"),
      },
    ],
  },

  {
    path: "/:pathMatch(.*)*",
    redirect: "/purchase/general/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
