<template>
  <div @click.stop="close">
    <div>
      <button
        type="button"
        class="btn btn-primary"
        id="button-open-dialog-number-pad"
        data-bs-toggle="modal"
        data-bs-target="#dialog-number-pad"
        hidden
      ></button>
      <div
        class="modal fade"
        tabindex="-1"
        id="dialog-number-pad"
        data-bs-backdrop="static"
      >
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-body mt-4">
              <NumberPad
                ref="numberPad"
                @input="handleInput"
                @delete="handleDelete"
              />
            </div>

            <div class="modal-footer mt-5">
              <button
                @click="close"
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
              >
                ปิด
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numbro from "numbro";
import NumberPad from "./NumberPad.vue";

export default {
  components: {
    NumberPad,
  },
  props: {
    dialogNumberPad: {
      type: Boolean,
      default: false,
    },
    inputValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      value: [],
      displayNumber: this.inputValue,
    };
  },
  computed: {
    dialogNumberPadComputed() {
      return this.dialogNumberPad;
    },
  },
  watch: {
    dialogNumberPadComputed: {
      handler(val) {
        if (val) {
          document.getElementById("button-open-dialog-number-pad").click();
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleInput(number) {
      this.value.push(number);
      this.displayNumber = this.value.join("");
      this.$emit("inputNumber", this.displayNumber);
    },
    handleDelete() {
      this.value.pop();
      this.displayNumber = this.value.join("");
      this.$emit("inputNumber", this.displayNumber);
    },
    close() {
      this.displayNumber = this.inputValue; // Reset input when closing
      this.$emit("closeDialogNumberPad");
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .display-phone {
    text-align: left !important;
  }
}
#close-btn:hover {
  background-color: LightGrey;
  border-radius: 25px;
  color: white;
}

.number-pad .row {
  margin-bottom: 5px;
}

.number-pad .row:last-child {
  margin-bottom: 0;
}
</style>
