<template>
  <div class="number-pad">
    <div class="row justify-content-center">
      <div class="col-4 d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-light"
          style="width: 100px; height: 100px"
          @click="input('7')"
        >
          7
        </button>
      </div>
      <div class="col-4 d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-light"
          style="width: 100px; height: 100px"
          @click="input('8')"
        >
          8
        </button>
      </div>
      <div class="col-4 d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-light"
          style="width: 100px; height: 100px"
          @click="input('9')"
        >
          9
        </button>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-4 d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-light"
          style="width: 100px; height: 100px"
          @click="input('4')"
        >
          4
        </button>
      </div>
      <div class="col-4 d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-light"
          style="width: 100px; height: 100px"
          @click="input('5')"
        >
          5
        </button>
      </div>
      <div class="col-4 d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-light"
          style="width: 100px; height: 100px"
          @click="input('6')"
        >
          6
        </button>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-4 d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-light"
          style="width: 100px; height: 100px"
          @click="input('1')"
        >
          1
        </button>
      </div>
      <div class="col-4 d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-light"
          style="width: 100px; height: 100px"
          @click="input('2')"
        >
          2
        </button>
      </div>
      <div class="col-4 d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-light"
          style="width: 100px; height: 100px"
          @click="input('3')"
        >
          3
        </button>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-4"></div>
      <div class="col-4 d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-light"
          style="width: 100px; height: 100px"
          @click="input('0')"
        >
          0
        </button>
      </div>
      <div class="col-4 d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-danger"
          style="width: 100px; height: 100px"
          @click="del()"
        >
          ⌫
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(["input", "delete"]);

const input = (value) => {
  emit("input", value);
};

const del = () => {
  emit("delete");
};
</script>

<style scoped>
.number-pad .row {
  margin-bottom: 10px;
}

.number-pad .row:last-child {
  margin-bottom: 0;
}
</style>
